import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Paragraph, Props as ParagraphProps } from './Paragraph'

export interface Props {
  paragraphs: ParagraphProps[]
  title?: string
}

export const AdvancedParagraph = memo(function AdvancedParagraph({
  paragraphs,
  title,
}: Props) {
  if (!paragraphs) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {uniqBy(paragraphs, 'title').map((item, index) => (
        <Paragraph key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section``

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin: 9.75rem auto -6rem;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 7.5rem auto -3.75rem;
  }
`
