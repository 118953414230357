import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container>
      {icon ? <SVG src={icon} width="24" height="20" alt={label} /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled.div`
  width: 25%;
  margin-top: 5.3125rem;
  padding: 0 1.125rem;

  @media (max-width: 767px) {
    width: 50%;
    margin-top: 3.75rem;
  }
`

const SVG = styled.img`
  width: auto;
  height: 1.5rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 1.25rem;
`
